import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TopbarComponent } from './topbar/topbar.component';
import { FooterComponent } from './footer/footer.component';
import { BannerComponent } from './banner/banner.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AdminCardComponent } from './admin-card/admin-card.component';
import { AdminbarComponent } from './adminbar/adminbar.component';
import { SeparadorComponent } from './separador/separador.component';
import { ValvulaMarcaComponent } from './valvulas/valvula-marca/valvula-marca.component';
import { AdminElementoComponent } from './admin-elemento/admin-elemento.component';
import { PipesModule } from '../pipes/pipes.module';
import { AdminProductosMasivoComponent } from './admin-productos-masivo/admin-productos-masivo.component';
import { FileUploadModule } from 'ng2-file-upload';
import { ProductoTarjetaComponent } from './producto-tarjeta/producto-tarjeta.component';
import { ProductoTarjetaSkeletonComponent } from './producto-tarjeta-skeleton/producto-tarjeta-skeleton.component';
import { AdminFacturasMasivoComponent } from './admin-facturas-masivo/admin-facturas-masivo.component';
import { IdiomaComponent } from './idioma/idioma.component';
import { TranslateModule } from '@ngx-translate/core';
import { MenuComponent } from './menu/menu.component';
import { AdminComprobantesMasivoComponent } from './admin-comprobantes-masivo/admin-comprobantes-masivo.component';

@NgModule({
  entryComponents: [
    AdminProductosMasivoComponent,
    AdminFacturasMasivoComponent,
    AdminComprobantesMasivoComponent,
    IdiomaComponent
  ],
  declarations: [
    TopbarComponent,
    FooterComponent,
    BannerComponent,
    AdminCardComponent,
    AdminbarComponent,
    SeparadorComponent,
    ValvulaMarcaComponent,
    AdminElementoComponent,
    AdminProductosMasivoComponent,
    ProductoTarjetaComponent,
    ProductoTarjetaSkeletonComponent,
    AdminFacturasMasivoComponent,
    AdminComprobantesMasivoComponent,
    IdiomaComponent,
    MenuComponent
  ],
  imports: [
    IonicModule,
    CommonModule,
    RouterModule,
    PipesModule,
    FileUploadModule,
    TranslateModule
  ],
  exports: [
    TopbarComponent,
    FooterComponent,
    BannerComponent,
    AdminCardComponent,
    AdminbarComponent,
    SeparadorComponent,
    ValvulaMarcaComponent,
    AdminElementoComponent,
    AdminProductosMasivoComponent,
    ProductoTarjetaComponent,
    ProductoTarjetaSkeletonComponent,
    AdminFacturasMasivoComponent,
    AdminComprobantesMasivoComponent,
    IdiomaComponent,
    MenuComponent
  ]
})
export class ComponentsModule {}
