import { Injectable } from '@angular/core';
import { from, Subject } from 'rxjs';
import { FeathersService } from '../feathers/feathers.service';
import { IUser } from 'src/app/models/user.model';

export const UserSubject: Subject<any> = new Subject();

@Injectable({
  providedIn: 'root'
})
export class AuthService extends FeathersService {
  constructor() {
    super();
  }

  authenticate() {
    return FeathersService._app
      .authenticate()
      .then(token => {
        return FeathersService._app.passport.verifyJWT(token.accessToken);
      })
      .then(payload => {
        return FeathersService._app
          .service(this._prefix + 'users')
          .get(payload.userId);
      })
      .then(user => {
        if (user) {
          FeathersService._app.set('user', user);
        }
        UserSubject.next(user);
        return user;
      })
      .catch(err => {
        UserSubject.next(null);
        return null;
      });
  }

  getUser() {
    return FeathersService._app.get('user');
  }

  getToken() {
    return FeathersService._app.passport.getJWT();
  }

  login(email: string, password: string) {
    return from(
      FeathersService._app
        .authenticate({
          strategy: 'local',
          email: email,
          password: password
        })
        .then(token => {
          return FeathersService._app.passport.verifyJWT(token.accessToken);
        })
        .then(payload => {
          return FeathersService._app
            .service(this._prefix + 'users')
            .get(payload.userId);
        })
        .then(user => {
          if (user) {
            FeathersService._app.set('user', user);
          }
          UserSubject.next(user);
          return user;
        })
    );
  }

  logout() {
    UserSubject.next(null);
    FeathersService._app.set('user', null);
    return from(FeathersService._app.logout());
  }

  changePassword(password, newPassword) {
    const usuario: IUser = this.getUser();
    return from(
      FeathersService._app.service('authManagement').create({
        action: 'passwordChange',
        value: {
          user: { email: usuario.email },
          oldPassword: password,
          password: newPassword
        }
      })
    );
  }

  resetPassword(email) {
    return from(
      FeathersService._app.service('authManagement').create({
        action: 'sendResetPwd',
        value: {
          email
        }
      })
    );
  }
}
