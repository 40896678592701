import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-adminbar',
  templateUrl: './adminbar.component.html',
  styleUrls: ['./adminbar.component.scss']
})
export class AdminbarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
