import { Component, OnInit } from '@angular/core';
import { FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import {
  ModalController,
  LoadingController,
  ToastController
} from '@ionic/angular';
import { UploadsService } from 'src/app/services/uploads/uploads.service';
import { FacturasService } from 'src/app/services/facturas/facturas.service';
import * as XLSX from 'xlsx';
import { Factura } from 'src/app/models/factura.model';
import { UserService } from 'src/app/services/user/user.service';

import { parseStringPromise } from 'xml2js';
import {
  stripPrefix,
  firstCharLowerCase,
  parseBooleans,
  parseNumbers
} from 'xml2js/lib/processors';

@Component({
  selector: 'app-admin-facturas-masivo',
  templateUrl: './admin-facturas-masivo.component.html',
  styleUrls: ['./admin-facturas-masivo.component.scss']
})
export class AdminFacturasMasivoComponent implements OnInit {
  public uploaderPDFs: FileUploader;
  public uploaderXMLs: FileUploader;
  public archivoSobrePDFs = false;
  public archivoSobreXMLs = false;
  PDFsCargadas: any = {};
  XMLsCargados: any = {};

  comprobantes: any = {};
  archivoFacturas: any;

  constructor(
    private _modal: ModalController,
    private _api: UploadsService,
    private _loading: LoadingController,
    private _toast: ToastController,
    private _facturas: FacturasService,
    private _usuarios: UserService
  ) {}

  async ngOnInit() {
    this.uploaderPDFs = await this._api.getFileuploader();
    this.uploaderXMLs = await this._api.getFileuploader();

    this.uploaderPDFs.onBeforeUploadItem = (item: FileItem) => {
      item.withCredentials = false;
    };
    this.uploaderXMLs.onBeforeUploadItem = (item: FileItem) => {
      item.withCredentials = false;
    };

    this.uploaderPDFs.onCompleteItem = (
      item: FileItem,
      response: any,
      status: number,
      headers: ParsedResponseHeaders
    ) => {
      this.PDFsCargadas[item.file.name.split('.')[0]] = {
        archivo: JSON.parse(response).id,
        nombre: item.file.name
      };
    };
    this.uploaderXMLs.onCompleteItem = (
      item: FileItem,
      response: any,
      status: number,
      headers: ParsedResponseHeaders
    ) => {
      const rf = new FileReader();
      rf.onload = ev => {
        parseStringPromise(rf.result, {
          mergeAttrs: true,
          explicitArray: false,
          tagNameProcessors: [stripPrefix, firstCharLowerCase],
          attrNameProcessors: [stripPrefix, firstCharLowerCase],
          attrValueProcessors: [parseBooleans, parseNumbers],
          valueProcessors: [parseBooleans, parseNumbers]
        }).then(xml => {
          this.comprobantes[item.file.name.split('.')[0]] = xml.comprobante;
        });
      };
      rf.readAsText(item._file);

      this.XMLsCargados[item.file.name.split('.')[0]] = {
        archivo: JSON.parse(response).id,
        nombre: item.file.name
      };
    };
  }

  async ngOnDestroy() {}

  adjuntaArchivo(file) {
    this.archivoFacturas = file.target.firstElementChild.files[0];
  }

  async cargarFacturas() {
    const cargando = await this._loading.create({
      message: 'Cargando facturas'
    });
    cargando.present();
    const promisePDFs = () =>
      new Promise(resolve => {
        if (this.uploaderPDFs.queue.length === 0) {
          resolve();
        }
        this.uploaderPDFs.uploadAll();
        this.uploaderPDFs.onCompleteAll = () => {
          resolve();
        };
      });
    const promiseXMLs = () =>
      new Promise((resolve, reject) => {
        if (this.uploaderXMLs.queue.length === 0) {
          resolve();
        }
        this.uploaderXMLs.uploadAll();
        this.uploaderXMLs.onCompleteAll = () => {
          resolve();
        };
      });

    Promise.all([promiseXMLs(), promisePDFs()]).then(async () => {
      Object.keys(this.comprobantes).forEach(async key => {
        const value = this.comprobantes[key];

        try {
          const existe = await this._facturas
            .get({
              query: {
                nombre: value.noCertificado.toString()
              }
            })
            .toPromise();

          const userId: any = await this._usuarios
            .get({ rfc: value.receptor.rfc })
            .toPromise();
          const pdf = this.PDFsCargadas[key];
          const xml = this.XMLsCargados[key];

          if (userId.length > 0) {
            const factura: Partial<Factura> = {
              userId: userId[0]._id,
              pdf: pdf.archivo,
              xml: xml.archivo,
              factura: value.noCertificado.toString(),
              ordenCompra: 'N/A',
              receptor: value.receptor,
              emisor: value.emisor,
              fechaFactura: value.fecha,
              subtotal: value.subTotal,
              iva: value.total - value.subTotal,
              total: value.total,
              comprobante: value
            };

            if (existe.length > 0) {
              await this._facturas.patch(existe[0]._id, factura).toPromise();
            } else {
              await this._facturas.post(factura).toPromise();
            }
          }
        } catch (error) {
          console.log(error);
        }
      });

      const terminado = await this._toast.create({
        message: 'Se cargaron correctamente las facturas',
        color: 'success',
        duration: 3000
      });
      this.cerrar();
      terminado.present();
      cargando.dismiss();
    });
  }

  cerrar() {
    this._modal.dismiss();
  }

  public fileOverPDFs(e: any): void {
    this.archivoSobrePDFs = e;
  }
  public fileOverXMLs(e: any): void {
    this.archivoSobreXMLs = e;
  }
}
