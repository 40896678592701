import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthenticatedGuard } from './guards/authenticated.guard';
import { IsAdminGuard } from './guards/is-admin.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: './pages/inicio/inicio.module#InicioPageModule',
    pathMatch: 'full'
  },
  {
    path: 'productos',
    children: [
      {
        path: '',
        loadChildren:
          './pages/catalogo/productos/productos.module#ProductosPageModule',
        pathMatch: 'full'
      },
      {
        path: ':tipo',
        loadChildren:
          './pages/catalogo/productos/productos.module#ProductosPageModule',
        pathMatch: 'full'
      },
      {
        path: ':tipo/:categoria',
        loadChildren:
          './pages/catalogo/productos/productos.module#ProductosPageModule',
        pathMatch: 'full'
      },
      {
        path: ':tipo/:categoria/:producto',
        loadChildren:
          './pages/catalogo/productos/productos.module#ProductosPageModule',
        pathMatch: 'full'
      }
      // {
      //   path: ':id',
      //   loadChildren: './pages/productos/productos.module#ProductosPageModule'
      // }
    ]
  },
  {
    path: 'rodamientos',
    loadChildren: './pages/rodamientos/rodamientos.module#RodamientosPageModule'
  },
  {
    path: 'valvulas',
    loadChildren: './pages/valvulas/valvulas.module#ValvulasPageModule'
  },
  {
    path: 'nosotros',
    loadChildren: './pages/nosotros/nosotros.module#NosotrosPageModule'
  },
  {
    path: 'contacto',
    loadChildren: './pages/contacto/contacto.module#ContactoPageModule'
  },
  {
    path: 'acceso',
    loadChildren: './pages/acceso/acceso.module#AccesoPageModule'
  },
  {
    path: 'mi-perfil',
    canActivate: [AuthenticatedGuard],
    children: [
      {
        path: '',
        loadChildren:
          './pages/perfil/opciones/opciones.module#OpcionesPageModule'
      },
      {
        path: 'cuenta',
        loadChildren: './pages/perfil/cuenta/cuenta.module#CuentaPageModule'
      },
      {
        path: 'facturas',
        loadChildren:
          './pages/perfil/facturas/facturas.module#FacturasPageModule'
      },
      {
        path: 'ordenes',
        loadChildren: './pages/perfil/ordenes/ordenes.module#OrdenesPageModule'
      },
      {
        path: 'comprobantes',
        loadChildren: () =>
          import('./pages/perfil/comprobantes/comprobantes.module').then(
            m => m.ComprobantesPageModule
          )
      }
    ]
  },
  {
    path: 'admin',
    canActivate: [AuthenticatedGuard, IsAdminGuard],
    children: [
      {
        path: '',
        loadChildren:
          './pages/admin/admin-opciones/admin-opciones.module#AdminOpcionesPageModule'
      },
      {
        path: 'contenido',
        children: [
          {
            path: 'banner-principal',
            loadChildren: () =>
              import(
                './pages/admin/contenido/banner-principal/banner-principal.module'
              ).then(m => m.BannerPrincipalPageModule)
          },
          {
            path: 'banner-de-clientes',
            loadChildren:
              './pages/admin/contenido/admin-banner-clientes/admin-banner-clientes.module#AdminBannerClientesPageModule'
          },
          {
            path: 'certificaciones-de-rodamientos',
            loadChildren:
              './pages/admin/contenido/admin-certificacion-rodamientos/admin-certificacion-rodamientos.module#AdminCertificacionRodamientosPageModule'
          },
          {
            path: 'casos-de-exito',
            loadChildren: () => import('./pages/admin/contenido/admin-casos-exito/admin-casos-exito.module').then( m => m.AdminCasosExitoPageModule)
          }
        ]
      },
      {
        path: 'productos',
        children: [
          {
            path: 'tipos',
            loadChildren:
              './pages/admin/productos/admin-tipo-productos/admin-tipo-productos.module#AdminTipoProductosPageModule'
          },
          {
            path: 'categorias',
            loadChildren:
              './pages/admin/productos/admin-categorias/admin-categorias.module#AdminCategoriasPageModule'
          },
          {
            path: 'marcas',
            loadChildren:
              './pages/admin/productos/admin-marcas/admin-marcas.module#AdminMarcasPageModule'
          },
          {
            path: 'listado',
            loadChildren:
              './pages/admin/productos/admin-productos/admin-productos.module#AdminProductosPageModule'
          }
        ]
      },
      {
        path: 'operacion',
        children: [
          {
            path: 'clientes',
            loadChildren:
              './pages/admin/operacion/admin-clientes/admin-clientes.module#AdminClientesPageModule'
          },
          {
            path: 'proveedores',
            loadChildren:
              './pages/admin/operacion/admin-proveedores/admin-proveedores.module#AdminProveedoresPageModule'
          },
          {
            path: 'ordenes',
            loadChildren:
              './pages/admin/operacion/admin-ordenes/admin-ordenes.module#AdminOrdenesPageModule'
          },
          {
            path: 'facturas',
            loadChildren:
              './pages/admin/operacion/admin-facturas/admin-facturas.module#AdminFacturasPageModule'
          },
          {
            path: 'comprobantes',
            loadChildren: () =>
              import(
                './pages/admin/operacion/admin-comprobantes/admin-comprobantes.module'
              ).then(m => m.AdminComprobantesPageModule)
          }
        ]
      },
      {
        path: 'sistema',
        children: [
          {
            path: 'usuarios',
            loadChildren:
              './pages/admin/sistema/admin-usuarios/admin-usuarios.module#AdminUsuariosPageModule'
          },
          {
            path: 'roles',
            loadChildren:
              './pages/admin/sistema/admin-roles/admin-roles.module#AdminRolesPageModule'
          }
        ]
      }
    ]
  },
  {
    path: 'pagina-no-existe',
    loadChildren:
      './pages/errores/pagina-no-existe/pagina-no-existe.module#PaginaNoExistePageModule'
  },
  {
    path: 'terminos-y-condiciones',
    loadChildren: './pages/terminos/terminos.module#TerminosPageModule'
  },
  {
    path: 'codigo-de-etica',
    loadChildren: './pages/etica/etica.module#EticaPageModule'
  },
  {
    path: 'aviso-de-privacidad',
    loadChildren: './pages/privacidad/privacidad.module#PrivacidadPageModule'
  },
  {
    path: 'politicas-de-ventas',
    loadChildren: () =>
      import('./pages/politica-ventas/politica-ventas.module').then(
        m => m.PoliticaVentasPageModule
      )
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./pages/forgot-password/forgot-password.module').then(
        m => m.ForgotPasswordPageModule
      )
  },
  {
    path: 'change-password',
    loadChildren: () =>
      import('./pages/change-password/change-password.module').then(
        m => m.ChangePasswordPageModule
      )
  },
  {
    path: '**',
    redirectTo: 'pagina-no-existe'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      initialNavigation: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
