import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  @Input() apiImage: boolean = false;
  @Input() position: string = 'center';
  @Input() alto: string;
  @Input() imagen: string;
  @Input() titulo: string;
  @Input() subtitulo: string;
  constructor(public sanitizer: DomSanitizer) {}

  ngOnInit() {}
}
