import {
    Injectable
} from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router
} from '@angular/router';
import {
    Observable
} from 'rxjs';
import {
    ToastController
} from '@ionic/angular';
import {
    AuthService
} from '../services/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate {
    constructor(
        private _auth: AuthService,
        private _toast: ToastController,
        private _router: Router
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable < boolean > | Promise < boolean > | boolean {

        const user = this._auth.getUser()
        if (!user) {
            return this._auth.authenticate()
                .then(user => {
                    if (user) {
                        return true
                    } else {
                        this._toast.create({
                            message: 'Necesitas iniciar sesión para acceder a esta sección.',
                            color: 'danger',
                            duration: 3500
                        }).then((toast: HTMLIonToastElement) => {
                            toast.present();
                        })
                        this._router.navigate(['/', 'acceso']);
                        return false;
                    }
                })
                .catch(err => {
                    this._toast.create({
                        message: 'Ocurrió un error al validar tu acceso, por favor inicia sesión de nuevo.',
                        color: 'danger',
                        duration: 3500
                    }).then((toast: HTMLIonToastElement) => {
                        toast.present();
                    })
                    this._router.navigate(['/', 'acceso']);
                    return false;
                })
        } else {
            return true;
        }
    }
}