import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private menu: Array<any> = [
    {
      texto: '',
      ruta: '',
      icono: 'home',
      color: 'light',
      exact: true
    },
    {
      texto: 'LAYOUT.HEADER.PRODUCTOS',
      ruta: 'productos',
      color: 'light'
    },
    {
      texto: 'LAYOUT.HEADER.RODAMIENTOS',
      ruta: 'rodamientos',
      color: 'light'
    },
    {
      texto: 'LAYOUT.HEADER.VALVULAS',
      ruta: 'valvulas',
      color: 'light'
    },
    {
      texto: 'LAYOUT.HEADER.NOSOTROS',
      ruta: 'nosotros',
      color: 'light'
    },
    {
      texto: 'LAYOUT.HEADER.CONTACTO',
      ruta: 'contacto',
      color: 'secondary'
    }
  ];

  private accountMenu: Array<any> = [
    {
      texto: 'CUENTA.OPCIONES.MI_CUENTA.TITULO',
      ruta: 'cuenta',
      padre: 'mi-perfil',
      icono: 'person',
      detalle: 'CUENTA.OPCIONES.MI_CUENTA.TEXTO'
    },
    {
      texto: 'CUENTA.OPCIONES.MIS_FACTURAS.TITULO',
      ruta: 'facturas',
      padre: 'mi-perfil',
      icono: 'person',
      detalle: 'CUENTA.OPCIONES.MIS_FACTURAS.TEXTO'
    },
    {
      texto: 'CUENTA.OPCIONES.COMPROBANTES_DE_PAGO.TITULO',
      ruta: 'comprobantes',
      padre: 'mi-perfil',
      icono: 'person',
      detalle: 'CUENTA.OPCIONES.COMPROBANTES_DE_PAGO.TEXTO'
    }
    // {
    //   texto: 'Mis órdenes',
    //   ruta: 'ordenes',
    //   padre: 'mi-perfil',
    //   icono: 'person',
    //   detalle:
    //     'Revisa a detalle cada una de las órdenes que haz realizado con nosotros.'
    // }
  ];

  private adminMenu: Array<any> = [
    {
      grupo: 'Administración de contenido del sitio web',
      roles: ['admin', 'content'],
      opciones: [
        {
          texto: 'Banner principal',
          ruta: 'banner-principal',
          padre: 'admin/contenido',
          icono: 'person',
          detalle:
            'Administra las imágenes que aparecen en la parte principal del sitio web'
        },
        {
          texto: 'Banner de Clientes',
          ruta: 'banner-de-clientes',
          padre: 'admin/contenido',
          icono: 'person',
          detalle:
            'Administra los logotipos de clientes que aparecen en la pantalla principal'
        },
        {
          texto: 'Certificaciones de Rodamientos',
          ruta: 'certificaciones-de-rodamientos',
          padre: 'admin/contenido',
          icono: 'person',
          detalle:
            'Administra las imágenes de las certificaciones para la sección de rodamientos'
        },
        {
          texto: 'Casos de éxito',
          ruta: 'casos-de-exito',
          padre: 'admin/contenido',
          icono: 'person',
          detalle: 'Carga y administra los casos de éxito que tiene actualmente DCV Industrial'
        }
      ]
    },
    {
      grupo: 'Administración de productos',
      roles: ['admin', 'marketing'],
      opciones: [
        {
          texto: 'Tipo de Productos',
          ruta: 'tipos',
          padre: 'admin/productos',
          icono: 'person',
          detalle:
            'Administra los tipos de productos que maneja DCV Industrial en su inventario (rodamientos, válvulas, etc)'
        },
        {
          texto: 'Categorías',
          ruta: 'categorias',
          padre: 'admin/productos',
          icono: 'person',
          detalle:
            'Administra las diferentes categorías para cada uno de los tipos de productos'
        },
        {
          texto: 'Marcas',
          ruta: 'marcas',
          padre: 'admin/productos',
          icono: 'person',
          detalle:
            'Administra las diferentes marcas de válvulas y rodamientos que maneja DCV Industrial para la venta al público.'
        },
        {
          texto: 'Productos',
          ruta: 'listado',
          padre: 'admin/productos',
          icono: 'person',
          detalle:
            'Administra cada uno de los productos que ofrece DCV Industrial al mercado'
        }
      ]
    },
    {
      grupo: 'Administración de clientes y proveedores',
      roles: ['admin', 'ventas'],
      opciones: [
        {
          texto: 'Clientes',
          ruta: 'clientes',
          padre: 'admin/operacion',
          icono: 'person',
          detalle:
            'Administra todos los clientes con los que cuenta DCV Industrial, tanto aquellos que hayan comprado con anterioridad, como los que solo han solicitado alguna cotización.'
        },
        {
          texto: 'Proveedores',
          ruta: 'proveedores',
          padre: 'admin/operacion',
          icono: 'person',
          detalle:
            'Administra cada uno de los proveedores con los que DCV Industrial cuenta con relación comercial.'
        },
        {
          texto: 'Facturas',
          ruta: 'facturas',
          padre: 'admin/operacion',
          icono: 'person',
          detalle:
            'Administra las facturas que generas para tus clientes y las que te entregan tus proveedores.'
        },
        {
          texto: 'Comprobantes de pago',
          ruta: 'comprobantes',
          padre: 'admin/operacion',
          icono: 'person',
          detalle:
            'Administra los comprobantes de pago que generas para los pagos de tus clientes.'
        }
        // {
        //   texto: 'Ordenes',
        //   ruta: 'ordenes',
        //   padre: 'admin/operacion',
        //   icono: 'person',
        //   detalle:
        //     'Administra las órdenes que han realizado los clientes a través de la página y aplicación móvil.'
        // }
      ]
    },
    {
      grupo: 'Administración de la aplicación',
      roles: ['admin'],
      opciones: [
        {
          texto: 'Usuarios',
          ruta: 'usuarios',
          padre: 'admin/sistema',
          icono: 'person',
          detalle:
            'Administra todos los usuarios que se encuentran registrados en la aplicación, sin importar de qué tipo son.'
        },
        {
          texto: 'Roles',
          ruta: 'roles',
          padre: 'admin/sistema',
          icono: 'person',
          detalle:
            'Administra los tipos de roles para el acceso y funcionalidad de la aplicación'
        }
      ]
    }
  ];
  constructor() {}

  getMenu() {
    return of(this.menu);
  }

  getAccountMenu() {
    return of(this.accountMenu);
  }

  getAdminMenu() {
    return of(this.adminMenu);
  }
}
