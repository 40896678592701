import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-producto-tarjeta-skeleton',
  templateUrl: './producto-tarjeta-skeleton.component.html',
  styleUrls: ['./producto-tarjeta-skeleton.component.scss'],
})
export class ProductoTarjetaSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
