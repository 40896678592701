import { Injectable } from '@angular/core';
import { ApiService, ApiResource } from '../api/api.service';
import { Categoria } from 'src/app/models/categoria.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CategoriasService extends ApiService<Categoria> {
  constructor(http: HttpClient) {
    super(http, new ApiResource('categorias'));
  }
}
