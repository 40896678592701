import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  ModalController,
  LoadingController,
  ToastController
} from '@ionic/angular';
import { FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import { UploadsService } from 'src/app/services/uploads/uploads.service';
import * as XLSX from 'xlsx';
import { ProductoService } from 'src/app/services/producto/producto.service';
import { Producto } from 'src/app/models/producto.model';
import { MarcaService } from 'src/app/services/marca/marca.service';
import { TipoProductosService } from 'src/app/services/tipo-productos/tipo-productos.service';
import { CategoriasService } from 'src/app/services/categorias/categorias.service';

@Component({
  selector: 'app-admin-productos-masivo',
  templateUrl: './admin-productos-masivo.component.html',
  styleUrls: ['./admin-productos-masivo.component.scss']
})
export class AdminProductosMasivoComponent implements OnInit, OnDestroy {
  public uploaderImagenes: FileUploader;
  public uploaderDocumentos: FileUploader;
  public archivoSobreImagenes: boolean = false;
  public archivoSobreDocumentos: boolean = false;
  imagenesCargadas: Array<any> = [];
  documentosCargados: Array<any> = [];
  archivoProductos: any;

  constructor(
    private _modal: ModalController,
    private _api: UploadsService,
    private _loading: LoadingController,
    private _toast: ToastController,
    private _productos: ProductoService,
    private _marcas: MarcaService,
    private _tipoProductos: TipoProductosService,
    private _categorias: CategoriasService
  ) {}

  async ngOnInit() {
    this.uploaderImagenes = await this._api.getFileuploader();
    this.uploaderDocumentos = await this._api.getFileuploader();

    this.uploaderImagenes.onBeforeUploadItem = (item: FileItem) => {
      item.withCredentials = false;
    };
    this.uploaderDocumentos.onBeforeUploadItem = (item: FileItem) => {
      item.withCredentials = false;
    };

    this.uploaderImagenes.onCompleteItem = (
      item: FileItem,
      response: any,
      status: number,
      headers: ParsedResponseHeaders
    ) => {
      this.imagenesCargadas.push({
        archivo: JSON.parse(response).id,
        nombre: item.file.name
      });
    };
    this.uploaderDocumentos.onCompleteItem = (
      item: FileItem,
      response: any,
      status: number,
      headers: ParsedResponseHeaders
    ) => {
      this.documentosCargados.push({
        archivo: JSON.parse(response).id,
        nombre: item.file.name
      });
    };
  }

  async ngOnDestroy() {}

  adjuntaArchivo(file) {
    this.archivoProductos = file.target.firstElementChild.files[0];
  }

  async cargarProductos() {
    const cargando = await this._loading.create({
      message: 'Cargando productos'
    });
    cargando.present();
    const promiseImagenes = () =>
      new Promise(resolve => {
        if (this.uploaderImagenes.queue.length === 0) {
          resolve();
        }
        this.uploaderImagenes.uploadAll();
        this.uploaderImagenes.onCompleteAll = () => {
          resolve();
        };
      });
    const promiseDocumentos = () =>
      new Promise((resolve, reject) => {
        if (this.uploaderDocumentos.queue.length === 0) {
          resolve();
        }
        this.uploaderDocumentos.uploadAll();
        this.uploaderDocumentos.onCompleteAll = () => {
          resolve();
        };
      });

    Promise.all([promiseDocumentos(), promiseImagenes()])
      .then(async () => {
        const reader = new FileReader();
        reader.onload = async (ev: any) => {
          // const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
          /* read workbook */
          const bstr: string = ev.target.result;
          const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

          /* grab first sheet */
          const wsname: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];

          let data = XLSX.utils.sheet_to_json(ws);
          data.forEach(async (producto: Producto) => {
            try {
              const marca: any = await this._marcas
                .post({ nombre: producto.marca })
                .toPromise();
              producto.marca = marca ? marca._id : null;

              const categoria: any = await this._categorias
                .post({ nombre: producto.categoria })
                .toPromise();
              producto.categoria = categoria ? categoria._id : null;

              const tipo: any = await this._tipoProductos
                .post({ nombre: producto.tipoProducto })
                .toPromise();
              producto.tipoProducto = tipo ? tipo._id : null;

              const existe = await this._productos
                .get({
                  query: {
                    nombre: producto.nombre
                  }
                })
                .toPromise();

              const imagen = this.imagenesCargadas.find((imagen: any) => {
                return (
                  imagen.nombre.split('.')[0] == producto.Consecutivo ||
                  imagen.nombre.split('.')[0] == producto.nombre
                );
              });
              producto.imagen = imagen ? imagen.archivo : null;

              const documento = this.documentosCargados.find(
                (documento: any) => {
                  return (
                    documento.nombre.split('.')[0] == producto.Consecutivo ||
                    documento.nombre.split('.')[0] == producto.nombre
                  );
                }
              );
              producto.documento = documento ? documento.archivo : null;

              if (existe.length > 0) {
                if (!producto.documento) {
                  delete producto.documento;
                }
                if (!producto.imagen) {
                  delete producto.imagen;
                }
                await this._productos
                  .patch(existe[0]._id, producto)
                  .toPromise();
              } else {
                await this._productos.post(producto).toPromise();
              }
            } catch (error) {
              console.log(error);
            }
          });

          const terminado = await this._toast.create({
            message: 'Se cargaron correctamente los productos',
            color: 'success',
            duration: 3000
          });
          this.cerrar();
          terminado.present();
          cargando.dismiss();
        };
        if (!this.archivoProductos) {
          throw 'Debes seleccionar un archivo de productos';
        }
        await reader.readAsBinaryString(this.archivoProductos);
      })
      .catch(async err => {
        cargando.dismiss();
        const mensajeError = await this._toast.create({
          message: '[Error]: ' + (err.message || err),
          color: 'danger',
          duration: 5000
        });
        mensajeError.present();
      });
  }

  cerrar() {
    this._modal.dismiss();
  }

  public fileOverImagenes(e: any): void {
    this.archivoSobreImagenes = e;
  }
  public fileOverDocumentos(e: any): void {
    this.archivoSobreDocumentos = e;
  }
}
