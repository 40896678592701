import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-admin-elemento',
  templateUrl: './admin-elemento.component.html',
  styleUrls: ['./admin-elemento.component.scss']
})
export class AdminElementoComponent implements OnInit {
  @Input() item: any;
  @Input() titulo: string;
  @Input() subtitulo: string;

  @Output('edit') outEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output('delete') outDelete: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  edit() {
    this.outEdit.emit(this.item);
  }

  delete() {
    this.outDelete.emit(this.item);
  }
}
