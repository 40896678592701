import {
    Component,
    OnInit,
    Input
} from '@angular/core';

@Component({
    selector: 'app-valvula-marca',
    templateUrl: './valvula-marca.component.html',
    styleUrls: ['./valvula-marca.component.scss']
})
export class ValvulaMarcaComponent implements OnInit {
    @Input() marca: any;
    constructor() {}

    ngOnInit() {}

}