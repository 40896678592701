import { Injectable } from '@angular/core';
import { ApiService, ApiResource } from '../api/api.service';
import { HttpClient } from '@angular/common/http';
import { Producto } from 'src/app/models/producto.model';

@Injectable({
  providedIn: 'root'
})
export class ProductoService extends ApiService<Producto> {
  constructor(http: HttpClient) {
    super(http, new ApiResource('productos'));
  }
}
