import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'apiImageStyle'
})
export class ApiImageStylePipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}
  transform(value: any, args?: any): any {
    if (value) {
      return this._sanitizer.bypassSecurityTrustStyle(
        'url(' + environment.UPLOADS_URL + '/' + value + ')'
      );
    }
  }
}
