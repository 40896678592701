import { Component, OnInit } from "@angular/core";
import { MenuService } from "src/app/services/menu/menu.service";
import {
  AlertController,
  LoadingController,
  PopoverController
} from "@ionic/angular";
import { Router } from "@angular/router";
import { UserSubject, AuthService } from "src/app/services/auth/auth.service";
import { IdiomaComponent } from "../idioma/idioma.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"]
})
export class TopbarComponent implements OnInit {
  menu: Array<any> = [];
  user: any;
  idioma: string = this.translate.currentLang;
  constructor(
    private _menu: MenuService,
    private _alert: AlertController,
    private _loading: LoadingController,
    private _auth: AuthService,
    private _router: Router,
    private popoverCtrl: PopoverController,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.user = this._auth.getUser();
    this.translate.onLangChange.subscribe(value => {
      this.idioma = this.translate.currentLang;
    });
    UserSubject.subscribe(user => {
      if (user) {
        this.user = user;
      } else {
        delete this.user;
      }
    });
    this._menu.getMenu().subscribe(menu => {
      this.menu = menu;
    });
  }

  async cerrarSesion() {
    const cerrar = await this._alert.create({
      header: "Cerrar sesión",
      message: "¿Deseas cerrar sesión?",
      buttons: [
        {
          text: "No",
          role: "cancel"
        },
        {
          text: "Sí",
          handler: async () => {
            const cerrando = await this._loading.create({
              message: "Cerrando sesión"
            });
            cerrando.present();
            this._auth.logout().subscribe(
              () => {
                this._router.navigate(["/"]);
                cerrando.dismiss();
              },
              err => {
                cerrando.dismiss();
              }
            );
          }
        }
      ]
    });
    cerrar.present();
  }

  async abrirIdiomas(ev) {
    const popover = await this.popoverCtrl.create({
      component: IdiomaComponent,
      event: ev,
      translucent: true
    });
    return await popover.present();
  }
}
