import {
    Injectable
} from '@angular/core';
import {
    ApiService,
    ApiResource
} from '../api/api.service';
import {
    HttpClient
} from '@angular/common/http';
import {
    Marca
} from 'src/app/models/marca.model';

@Injectable({
    providedIn: 'root'
})
export class MarcaService extends ApiService < Marca > {
    constructor(http: HttpClient) {
        super(http, new ApiResource('marcas'));
    }
}