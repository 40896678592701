import {
    Injectable
} from '@angular/core';
import {
    ApiService,
    ApiResource
} from '../api/api.service';
import {
    User
} from 'src/app/models/user.model';
import {
    HttpClient
} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class UserService extends ApiService < User > {
    constructor(http: HttpClient) {
        super(http, new ApiResource('users'));
    }
}