import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-idioma',
  templateUrl: './idioma.component.html',
  styleUrls: ['./idioma.component.scss']
})
export class IdiomaComponent implements OnInit {
  constructor(
    private popoverCtrl: PopoverController,
    private translateService: TranslateService
  ) {}

  ngOnInit() {}

  cambiaIdioma(idioma) {
    this.translateService.use(idioma);
    window.localStorage.setItem('language', idioma);
    this.popoverCtrl.dismiss();
  }
}
