// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    MODE: 'DES',
    // UPLOADS_URL: 'http://localhost:3030/uploads',
    // API_URL: 'http://localhost:3030/',
    UPLOADS_URL: 'https://api.dcvindustrial.mx/uploads',
    API_URL: 'https://api.dcvindustrial.mx/',
    SOCKET_CONFIG: {},
    PREFIX: ''
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
