import { Injectable } from '@angular/core';
import { ApiService, ApiResource } from '../api/api.service';
import { HttpClient } from '@angular/common/http';
import { Comprobante } from 'src/app/models/comprobante.model';

@Injectable({
  providedIn: 'root'
})
export class ComprobantesService extends ApiService<Comprobante> {
  constructor(http: HttpClient) {
    super(http, new ApiResource('comprobantes'));
  }
}
