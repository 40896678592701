import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { FeathersService } from '../feathers/feathers.service';
import { FileUploader } from 'ng2-file-upload';

export class ApiResource {
  route: string;
  constructor(route: string) {
    this.route = route;
  }
}

export interface IBaseDbObject {
  _id: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService<DataType> extends AuthService {
  private _service;
  constructor(private _http: HttpClient, protected _resource: ApiResource) {
    super();
    this._service = FeathersService._app.service(
      this._prefix + this._resource.route
    );
  }

  get resource() {
    return this._resource;
  }

  get(params?: any): Observable<any> {
    return from(
      this._service.find(params).catch(err => {
        if (err.name === 'NotAuthenticated') super.logout();
        return null;
      })
    );
  }
  getOne(id: string) {
    return from(
      this._service.get(id).catch(err => {
        if (err.name === 'NotAuthenticated') super.logout();
        return {};
      })
    );
  }
  post(body: any, params?: any) {
    return from(
      this._service.create(body, params).catch(err => {
        if (err.name === 'NotAuthenticated') super.logout();
        return {};
      })
    );
  }
  async upload(body: any) {
    const token = await FeathersService._app.passport.getJWT();
    return this._http
      .post(environment.UPLOADS_URL, body, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .toPromise();
  }
  async getFileuploader() {
    const token = await FeathersService._app.passport.getJWT();

    return new FileUploader({
      url: environment.UPLOADS_URL,
      // authToken: token
      headers: [
        {
          name: 'Authorization',
          value: `Bearer ${token}`
        }
      ]
    });
  }
  put(id: string, body: any) {
    return from(
      this._service.patch(id, body).catch(err => {
        if (err.name === 'NotAuthenticated') super.logout();
        return {};
      })
    );
  }
  patch(id: string, body: Partial<DataType>) {
    return from(
      this._service.patch(id, body).catch(err => {
        if (err.name === 'NotAuthenticated') super.logout();
        return {};
      })
    );
  }
  delete(id: string) {
    return from(
      this._service.remove(id).catch(err => {
        if (err.name === 'Forbidden') throw err;
        if (err.name === 'NotAuthenticated') super.logout();
        return {};
      })
    );
  }
  subscribeCollection(collection: Array<any>) {
    this._service.on('created', res => {
      collection.push(res);
    });
    this._service.on('updated', res => {
      collection.splice(
        collection.findIndex(c => c._id === res._id),
        1,
        res
      );
    });
    this._service.on('patched', res => {
      collection.splice(
        collection.findIndex(c => c._id === res._id),
        1,
        res
      );
    });
    this._service.on('removed', res => {
      collection.splice(
        collection.findIndex(c => c._id === res._id),
        1
      );
    });
  }
  subscribeCollectionWithFilter(
    collection: Array<any>,
    filter: string,
    value: string
  ) {
    this._service.on('created', res => {
      if (res[filter] === value || res[filter].indexOf(value) > -1) {
        collection.push(res);
      }
    });
    this._service.on('updated', res => {
      if (res[filter] === value || res[filter].indexOf(value) > -1) {
        if (collection.findIndex(c => c._id === res._id) > -1) {
          collection.splice(
            collection.findIndex(c => c._id === res._id),
            1,
            res
          );
        } else {
          collection.push(res);
        }
      }
    });
    this._service.on('patched', res => {
      if (res[filter] === value || res[filter].indexOf(value) > -1) {
        if (collection.findIndex(c => c._id === res._id) > -1) {
          collection.splice(
            collection.findIndex(c => c._id === res._id),
            1,
            res
          );
        } else {
          collection.push(res);
        }
      }
    });
    this._service.on('removed', res => {
      if (res[filter] === value || res[filter].indexOf(value) > -1) {
        collection.splice(
          collection.findIndex(c => c._id === res._id),
          1
        );
      }
    });
  }
  unsubscribeCollection() {
    this._service.off('created', () => {});
    this._service.off('removed', () => {});
    this._service.off('updated', () => {});
    this._service.off('patched', () => {});
  }
}
