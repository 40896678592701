import { Component } from '@angular/core';

import { Platform, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MenuService } from './services/menu/menu.service';
import { Router } from '@angular/router';
import { AuthService } from './services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  menu: Array<any> = [];
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private _menu: MenuService,
    private _router: Router,
    private _menuCtrl: MenuController,
    private _auth: AuthService,
    private translate: TranslateService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.translate.setDefaultLang('es-mx');
    this.translate.langs = ['es-mx', 'en-us'];
    this.translate.use(window.localStorage.getItem('language') || 'es-mx');
    this.translate.use('es-mx');

    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
      }
    });

    this._auth.authenticate();

    this._menu.getMenu().subscribe(menu => {
      this.menu = menu;
    });
  }

  irPagina(pagina) {
    this._router.navigate(['/', pagina.ruta]).then(() => {
      this._menuCtrl.close();
    });
  }
}
