import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class IsAdminGuard implements CanActivate {
  constructor(
    private _auth: AuthService,
    private _toast: ToastController,
    private _router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const user = this._auth.getUser();
    if (!user) {
      return this._auth
        .authenticate()
        .then(user => {
          if (
            user &&
            (user.roles.indexOf('admin') > -1 ||
              user.roles.indexOf('content') > -1 ||
              user.roles.indexOf('marketing') > -1 ||
              user.roles.indexOf('ventas') > -1)
          ) {
            return true;
          } else {
            this._toast
              .create({
                message: 'No tienes permiso para acceder a esta sección.',
                color: 'danger',
                duration: 3500
              })
              .then((toast: HTMLIonToastElement) => {
                toast.present();
              });
            this._router.navigate(['/']);
            return false;
          }
        })
        .catch(err => {
          this._toast
            .create({
              message:
                'Ocurrió un error al validar tu acceso, por favor inicia sesión de nuevo.',
              color: 'danger',
              duration: 3500
            })
            .then((toast: HTMLIonToastElement) => {
              toast.present();
            });
          this._router.navigate(['/', 'acceso']);
          return false;
        });
    } else {
      return true;
    }
  }
}
