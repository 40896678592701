import { Injectable } from '@angular/core';
import { ApiService, ApiResource } from '../api/api.service';
import { Factura } from 'src/app/models/factura.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FacturasService extends ApiService<Factura> {
  constructor(http: HttpClient) {
    super(http, new ApiResource('facturas'));
  }
}
