import {
    Component,
    OnInit,
    Input
} from '@angular/core';

@Component({
    selector: 'app-separador',
    templateUrl: './separador.component.html',
    styleUrls: ['./separador.component.scss']
})
export class SeparadorComponent implements OnInit {
    @Input() titulo: string;
    @Input() subtitulo: string;
    constructor() {}

    ngOnInit() {}

}