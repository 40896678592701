import { Injectable } from '@angular/core';
import { TipoProducto } from 'src/app/models/tipo-productos.model';
import { ApiService, ApiResource } from '../api/api.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TipoProductosService extends ApiService<TipoProducto> {
  constructor(http: HttpClient) {
    super(http, new ApiResource('tipo-productos'));
  }
}
