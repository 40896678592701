import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-producto-tarjeta',
  templateUrl: './producto-tarjeta.component.html',
  styleUrls: ['./producto-tarjeta.component.scss']
})
export class ProductoTarjetaComponent implements OnInit {
  @Input() producto;
  constructor() {}

  ngOnInit() {}
}
