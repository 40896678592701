import { ApiImagePipe } from './api-image/api-image.pipe';
import { NgModule } from '@angular/core';
import { ApiFilePipe } from './api-file/api-file.pipe';
import { ApiImageStylePipe } from './api-image-style/api-image-style.pipe';

@NgModule({
  declarations: [ApiImagePipe, ApiFilePipe, ApiImageStylePipe],
  exports: [ApiImagePipe, ApiFilePipe, ApiImageStylePipe]
})
export class PipesModule {}
