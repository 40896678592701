import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-card',
  templateUrl: './admin-card.component.html',
  styleUrls: ['./admin-card.component.scss']
})
export class AdminCardComponent implements OnInit {
  @Input() pagina: any;
  constructor(private _router: Router) {}

  ngOnInit() {}

  openPage(pagina) {
    const ruta: Array<string> = this.pagina.padre.split('/');
    ruta.unshift('/');
    ruta.push(this.pagina.ruta);
    this._router.navigate(ruta);
  }
}
