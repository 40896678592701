import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'apiImage'
})
export class ApiImagePipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}
  transform(value: any, args?: any): any {
    if (value)
      return this._sanitizer.bypassSecurityTrustUrl(
        environment.UPLOADS_URL + '/' + value
      );
  }
}
