import { Injectable } from '@angular/core';
import feathers, { Application } from '@feathersjs/feathers';
import auth from '@feathersjs/authentication-client';
import socketio from '@feathersjs/socketio-client';
import * as io from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeathersService {
  protected static isInitialized = false;
  protected static _app: Application<any> = feathers();
  private _socketConfig = environment.SOCKET_CONFIG;
  private _url: string = environment.API_URL;
  private _socket = io(this._url, this._socketConfig);
  protected _prefix: string = environment.PREFIX;
  constructor() {
    if (FeathersService.isInitialized === false) {
      FeathersService._app.configure(
        socketio(this._socket, {
          timeout: 120000
        })
      );
      FeathersService._app.configure(
        auth({
          storageKey: 'dcv-industrial-key',
          storage: window.localStorage
        })
      );
      FeathersService.isInitialized = true;
    }
  }
}
