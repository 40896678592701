import { Injectable } from '@angular/core';
import { ApiResource, ApiService } from '../api/api.service';
import { Producto } from 'src/app/models/producto.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UploadsService extends ApiService<Producto> {
  constructor(_http: HttpClient) {
    super(_http, new ApiResource('uploads'));
  }
}
